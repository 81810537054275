import axios from "axios";
import React, { useEffect, useState } from "react";
import "./pending.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function Pending() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        const filteredProducts = response.data.filter(
          (product) => product.confirm == 0
        );
        setProducts(filteredProducts);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/delete`, {
        withCredentials: false,
      })
      .then(function (response) {
        getProducts(); // Ürünleri tekrar alarak listeyi güncelle
      });
  };

  const acceptUser = (id) => {
    axios
      .put(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/update`, {
        id: id,        // ID'yi açıkça gönderiyoruz
        confirm: 1     // confirm değerini 1 olarak gönderiyoruz
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: false
      })
      .then(function (response) {
        console.log("User updated:", response.data); // Yanıtı konsolda göster
        getProducts(); // Listeyi güncelle
      })
      .catch(function (error) {
        console.error("Error updating user:", error); // Hataları konsolda göster
      });
  };
  

  const downloadFile = (base64String, fileType, fileName) => {
    const link = document.createElement('a');
    link.href = `data:${fileType};base64,${base64String}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  
  


  

  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <table>
            <thead>
              <tr>
                <th>Sil</th>
                <th>Qəbul</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Ata adı</th>
                <th>Şəhid varmı?</th>
                <th>Şifrə</th>
                <th>Hid nömrə</th>
                <th>FİN Kod</th>
                <th>Ad günü</th>
                <th>Şöbə</th>
                <th>Vəzifə</th>
                <th>İşə qəbul</th>
                <th>Daxili nömrə</th>
                <th>Mobil nömrə</th>
                <th>Email</th>
                <th>Şəkil</th>
                <th>Şəhidlik sənədi</th>
                <th>Qeydiyyat tarixi</th>
                
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>
                    <button onClick={() => acceptUser(user.id)}>Qəbul</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.father}</td>
                  <td>{user.isMartyr == 1 ? 'Bəli' : 'Xeyr'}</td>
                  <td>{user.password}</td>
                  <td>{user.hidNumber}</td>
                  <td>{user.finKod}</td>
                  <td>{user.birthday}</td>
                  <td>{user.department_id}</td>
                  <td>{user.position}</td>
                  <td>{user.acceptanceToWork}</td> 
                  <td>{user.innerNumber}</td>
                  <td>{user.mobileNumber}</td>
                  <td>{user.email}</td>
                  <td>
                  <img src={`data:image/jpeg;base64,${user.image}`} alt='' />
                </td>
                <td>
                    {user.martyrDocument ? (
                      <button onClick={() => downloadFile(user.martyrDocument, 'application/octet-stream', 'martyrDocument')} >Yüklə</button>
                    ) : (
                      'Yoxdur'
                    )}
                  </td>
                  <td>{user.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

