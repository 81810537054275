import React from 'react'
import { useTranslation } from 'react-i18next';

const Leaders = () => {

  const { t, i18n } = useTranslation();
  return (
    <section className="team-section">
      <div className="auto-container">
        <div className="styled-heading centered">
          <h2>{t("İcraçı komanda")}</h2>
        </div>
        <div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                <div></div>
                <div></div>
            <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image2.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Şadman Əmirxanlı </h3>
                <div className="designation">{t("Sədr")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                <li><span className="icon fa fa-phone"></span> <a href="#">+994508432031</a></li>
                <li><span className="icon fa fa-phone"></span> <a href="#">32031</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">shadman.amirxanli@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>
            </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>


          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image7.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Qurban Qurbanov</h3>
                <div className="designation">{t("Xəzinədar")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432091</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32091</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">qurban.a.qurbanov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image8.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Sənan Səfərov</h3>
                <div className="designation">{t("Biznes Tərəfdaş")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432113</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32113</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">senan.seferov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image1.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Dilqəm Umbayev</h3>
                <div className="designation">{t("İnformasiya Texnologiyaları üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432191</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32191</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">dilqem.umbayev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>


          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image13.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Səidə Abdullayeva</h3>
                <div className="designation">{t("Biletlər və göndərişlər üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432054</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32054</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">seide.abdullayeva@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image14.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Ramil Bayramov</h3>
                <div className="designation">{t("Biznes Tərəfdaş")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432016</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32016</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">ramil.r.bayramov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>


          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image16.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Həmidə Xasanova</h3>
                <div className="designation">{t("Kommunikasiya və tədbirlər üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                {/* <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#"></a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#"></a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#"></a></li>
                </ul> */}
                 
              </div>
            </div>
          </div>

          </div>
      </div>
    </section>
  )
}

export default Leaders