import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EventDetails = () => {

  const { t, i18n } = useTranslation();

    const {id} = useParams();
  return (
    <>
        <div>{t("Tədbir")} - {id}</div>
    </>
  )
}

export default EventDetails