import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';
import './workers.css'




const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "10px",
        top: "26%", // Daha yukarıda konumlandırmak için değeri azaltın
        zIndex: "1",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
};

// Custom previous button
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px",
        top: "26%", // Daha yukarıda konumlandırmak için değeri azaltın
        zIndex: "1",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
};




const Workers = () => {

  const { t, i18n } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    nextArrow: <NextArrow />, // Use the custom next button
    prevArrow: <PrevArrow />, // Use the custom previous button
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section className="team-section">
      <div className="auto-container">
        <div className="styled-heading centered">
          <h2>{t("Komandamız")}</h2>
        </div>
        <Slider {...settings}>
          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image1.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Dilqəm Umbayev</h3>
                <div className="designation">{t("İnformasiya Texnologiyaları üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432191</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32191</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">dilqem.umbayev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image2.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Şadman Əmirxanlı </h3>
                <div className="designation">{t("Sədr")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                <li><span className="icon fa fa-phone"></span> <a href="#">+994508432031</a></li>
                <li><span className="icon fa fa-phone"></span> <a href="#">32031</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">shadman.amirxanli@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image3.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Əhmədov Qurban</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432136</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32136</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">qurban.ahmedov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image4.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Sabir Əliyev</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432170</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32179</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">sabir.f.aliyev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image5.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Nərmin Məmmədov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432093</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32093</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">nermin.e.memmedova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image6.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Ramiz Qədirov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432108</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32108</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">ramiz.qadirov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image7.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Qurban Qurbanov</h3>
                <div className="designation">{t("Xəzinədar")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432091</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32091</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">qurban.a.qurbanov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image8.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Sənan Səfərov</h3>
                <div className="designation">{t("Biznes Tərəfdaş")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432113</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32113</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">senan.seferov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image9.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Səidə Dadaşova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432452</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32452</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">saida.i.dadashova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image10.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Nəsimi Quliyev</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432135</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32135</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">nasimi.quliyev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image11.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Samir Kərimov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432078</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32078</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">samir.a.karimov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="/assets/images/resource/team-image-problem.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Həcər Muradova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432106</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32106</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">hecer.a.muradova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image13.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Səidə Abdullayeva</h3>
                <div className="designation">{t("Biletlər və göndərişlər üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432054</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32054</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">seide.abdullayeva@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image14.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Ramil Bayramov</h3>
                <div className="designation">{t("Biznes Tərəfdaş")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432016</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32016</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">ramil.r.bayramov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image15.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Əfsanə Sayılova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432199</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32199</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">efsane.sayilova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image16.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Həmidə Xasanova</h3>
                <div className="designation">{t("Kommunikasiya və tədbirlər üzrə məsul şəxs")}</div>
              </div>
              <div className="content">
                {/* <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#"></a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#"></a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#"></a></li>
                </ul> */}
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp ">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image17.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Solmaz Kazımova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432011</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32011</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">solmaz.kazimova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Workers;

