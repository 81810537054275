import React from 'react'
import { useTranslation } from 'react-i18next';

const Members = () => {

  const { t, i18n } = useTranslation();
  return (
    <section className="team-section">
      <div className="auto-container">
        <div className="styled-heading centered">
          <h2>{t("Komitə üzvləri")}</h2>
        </div>
        <div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                <div></div>
                <div></div>
            <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image2.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Şadman Əmirxanlı </h3>
                <div className="designation">{t("Sədr")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                <li><span className="icon fa fa-phone"></span> <a href="#">+994508432031</a></li>
                <li><span className="icon fa fa-phone"></span> <a href="#">32031</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">shadman.amirxanli@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>
            </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>

        <div className="column team-member wow fadeInUp ">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image17.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Solmaz Kazımova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432011</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32011</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">solmaz.kazimova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>


          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image3.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Əhmədov Qurban</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432136</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32136</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">qurban.ahmedov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image4.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Sabir Əliyev</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432170</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32179</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">sabir.f.aliyev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image5.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Nərmin Məmmədov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432093</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32093</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">nermin.e.memmedova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image6.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Ramiz Qədirov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432108</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32108</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">ramiz.qadirov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image9.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Səidə Dadaşova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432452</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32452</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">saida.i.dadashova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image10.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Nəsimi Quliyev</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432135</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32135</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">nasimi.quliyev@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image11.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Samir Kərimov</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432078</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32078</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">samir.a.karimov@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="/assets/images/resource/team-image-problem.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Həcər Muradova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432106</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32106</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">hecer.a.muradova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>



          <div className="column team-member wow fadeInUp">
            <div className="inner-box">
              <figure className="image">
                <a href="mailto:mail@email.com">
                  <img src="assets/images/resource/team-image15.jpg" alt=""/>
                </a>
              </figure>
              <div className="member-info">
                <h3>Əfsanə Sayılova</h3>
                <div className="designation">{t("Üzv")}</div>
              </div>
              <div className="content">
                <ul className="contact-info">
                  <li><span className="icon fa fa-phone"></span> <a href="#">+994508432199</a></li>
                  <li><span className="icon fa fa-phone"></span> <a href="#">32199</a></li>
                  <li><span className="icon fa fa-envelope-o"></span> <a href="#">efsane.sayilova@socar.az</a></li>
                </ul>
                 
              </div>
            </div>
          </div>

          </div>
      </div>
    </section>
  )
}

export default Members