import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function Dashboard() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", {
        withCredentials: false,
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .then(function (response) {
        const filteredProducts = response.data.filter(product => product.confirm == 1);
        setProducts(filteredProducts);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/delete`, {
        withCredentials: false,
      })
      .then(function (response) {
        getProducts();
      });
  };

  const uploadMartyrDocument = (id) => {
    const formData = new FormData();
    const input = document.getElementById(`martyrDocumentInput-${id}`);
    if (input.files.length > 0) {
      formData.append('martyrDocument', input.files[0]);

      axios
        .post(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          alert(response.data.message);
          getProducts(); // Yenile
        })
        .catch(error => {
          console.error("There was an error uploading the file!", error);
        });
    } else {
      alert("Lütfen bir dosya seçin.");
    }
  };

  const downloadFile = (base64String, fileType, fileName) => {
    const link = document.createElement('a');
    link.href = `data:${fileType};base64,${base64String}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <>
    <DashboardHeader />
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container"></div>
    </nav>
    <div className="container" style={{ paddingTop: 50 }}>
      <div>
        <table>
          <thead>
            <tr>
              <th>Sil</th>
              <th>ID</th>
              <th>Ad</th>
              <th>Soyad</th>
              <th>Ata adı</th>
              <th>Şəhid varmı?</th>
              <th>Şifrə</th>
              <th>Hid nömrə</th>
              <th>FİN Kod</th>
              <th>Ad günü</th>
              <th>Şöbə/Sahə</th>
              <th>Vəzifə</th>
              <th>İşə qəbul</th>
              <th>Daxili nömrə</th>
              <th>Mobil nömrə</th>
              <th>Email</th>
              <th>Şəkil</th>
              <th>Şəhidlik sənədi</th>
              <th>Qeydiyyat tarixi</th>
            </tr>
          </thead>
          <tbody>
            {products.map((user, key) => (
              <tr key={key}>
                <td>
                  <button onClick={() => deleteUser(user.id)}>Sil</button>
                </td>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.father}</td>
                <td>{user.isMartyr == 1 ? 'Bəli' : 'Xeyr'}</td>
                <td>{user.password}</td>
                <td>{user.hidNumber}</td>
                <td>{user.finKod}</td>
                <td>{user.birthday}</td>
                <td>{user.department_id}</td>
                <td>{user.position}</td>
                <td>{user.acceptanceToWork}</td>
                <td>{user.innerNumber}</td>
                <td>{user.mobileNumber}</td>
                <td>{user.email}</td>
                <td>
                  <img src={`data:image/jpeg;base64,${user.image}`} alt='' />
                </td>
                <td>
                    {user.martyrDocument ? (
                      <button onClick={() => downloadFile(user.martyrDocument, 'application/octet-stream', 'martyrDocument')} >Yüklə</button>
                    ) : (
                      'Yoxdur'
                    )}
                  </td>
                <td>{user.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
  );
}



