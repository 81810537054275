import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const NewsDetails = () => {

  const { t, i18n } = useTranslation();

  const {id} = useParams();
  return (
    <>
    <div>{t("Xəbər")} - {id}</div>
</>
  )
}

export default NewsDetails