import React from 'react'
import { useTranslation } from 'react-i18next';

const News = () => {

    const { t, i18n } = useTranslation();
  return (
    <>
    <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
            <div class="auto-container">
            	<h1>{t("Xəbərlər")}</h1>
            </div>
        </section>
        <section class="page-info">
            <div class="auto-container clearfix">
            	<div class="pull-left"><h2>{t("Xəbərlər")}</h2></div>
                <div class="pull-right">
                	<ul class="bread-crumb clearfix">
                    	<li><a href="/">{t("Əsas səhifə")}</a></li>
                        <li>{t("Xəbərlər")}</li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="news-section">
            <div class="auto-container">
                
                <div class="row clearfix">
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-1.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">21<br></br>Aug</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Criminal Law</a>, <a href="#">Kidnapping</a></div>
                                <h3><a href="#">We won against criminal</a></h3>
                                <div class="text"><p>In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.</p></div>
                                <a href="/news/1" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-2.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">27<br></br>Dec</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Family Law</a>, <a href="#">Adultery</a></div>
                                <h3><a href="#">Legal issues regarding paternity</a></h3>
                                <div class="text"><p>Adultery  is extramarital sex that is considered objectionable on social, religious, moral or legal grounds though what sexual activities.</p></div>
                                <a href="/news/2" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-3.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">14<br></br>Jan</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Consumer Law</a>, <a href="#">Privacy</a></div>
                                <h3><a href="#">Judgement, Unfair business</a></h3>
                                <div class="text"><p>Consumer prottection law or consumeer law is considered an area of law that regulates private law relationships between individual.</p></div>
                                <a href="/news/3" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>

                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-1.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">21<br></br>Aug</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Criminal Law</a>, <a href="#">Kidnapping</a></div>
                                <h3><a href="#">We won against criminal</a></h3>
                                <div class="text"><p>In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.</p></div>
                                <a href="/news/4" class="theme-btn read-more">{t('Daha çox')}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-2.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">27<br></br>Dec</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Family Law</a>, <a href="#">Adultery</a></div>
                                <h3><a href="#">Legal issues regarding paternity</a></h3>
                                <div class="text"><p>Adultery  is extramarital sex that is considered objectionable on social, religious, moral or legal grounds though what sexual activities.</p></div>
                                <a href="/news/5" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-3.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">14<br></br>Jan</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Consumer Law</a>, <a href="#">Privacy</a></div>
                                <h3><a href="#">Judgement, Unfair business</a></h3>
                                <div class="text"><p>Consumer prottection law or consumeer law is considered an area of law that regulates private law relationships between individual.</p></div>
                                <a href="/news/6" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>

                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-1.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">21<br></br>Aug</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Criminal Law</a>, <a href="#">Kidnapping</a></div>
                                <h3><a href="#">We won against criminal</a></h3>
                                <div class="text"><p>In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.</p></div>
                                <a href="/news/7" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-2.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">27<br></br>Dec</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Family Law</a>, <a href="#">Adultery</a></div>
                                <h3><a href="#">Legal issues regarding paternity</a></h3>
                                <div class="text"><p>Adultery  is extramarital sex that is considered objectionable on social, religious, moral or legal grounds though what sexual activities.</p></div>
                                <a href="/news/8" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                    {/* <!--Column--> */}
                    <article class="column featured-news-column col-md-4 col-sm-6 col-xs-12">
                        <div class="inner-box">
                            <figure class="image-box">
                            	<img src="assets/images/resource/blog-image-3.jpg" alt=""></img>
                                <a href="#" class="default-overlay-outer">
                                    <div class="inner">
                                        <div class="content-layer">
                                            <div class="link-icon"><span class="fa fa-link"></span></div>
                                        </div>
                                    </div>
                                </a>
                            </figure>
                            <div class="content">
                            	<div class="date">14<br></br>Jan</div>
                                
                            	<div class="tags"><span class="icon fa fa-tags"></span>&ensp; <a href="#">Consumer Law</a>, <a href="#">Privacy</a></div>
                                <h3><a href="#">Judgement, Unfair business</a></h3>
                                <div class="text"><p>Consumer prottection law or consumeer law is considered an area of law that regulates private law relationships between individual.</p></div>
                                <a href="/news/9" class="theme-btn read-more">{t("Daha çox")}</a>
                            </div>
                        </div>
                    </article>
                    
                </div>
                
            </div>
        </section>
        </>
  )
}

export default News