import axios from 'axios';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Polls from '../Polls/Polls'

const Offers = () => {
  const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const memberName = useSelector((state) => state.auth.member);
    const memberSurname = useSelector((state) => state.auth.surname);
    const userId = useSelector((state) => state.auth.userId); // Redux'dan userId'yi al

    const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if(!memberName) {
//         navigate('/login')
//     }
//     else {
//         let inputs = { name: memberName, email: memberSurname, phone: inputValue };

//         try {
//             console.log(inputs);
            
//             // Seçilen ürünleri post isteği ile gönder
//             const response = await axios.post("https://www.inteko.az/inteko/contact.php/", inputs);
            
//             // Başarılı olduğunda yapılacak işlemler
//             toast.success("Form göndərildi");
            
//             // Clear form after successful submission
//             setclientName('');
//             setclientEmail('');
//             setclientPhone('');
//             setclientMessage('');
//             setclientWish('');
            
//             navigate('/');
            
//           } catch (error) {
//             // Hata durumunda yapılacak işlemler
//             console.error("Sipariş gönderilirken bir hata oluştu:", error);
//             toast.error("Form göndərilərkən xəta baş verdi");
//           }
//     }
//   };

const handleSubmit = async (e) => {
    e.preventDefault();
  
    if(memberName && memberSurname) {
        // Add offer and created_at field
        let inputs = {
            name: memberName,
            surname: memberSurname,
            offer: inputValue, // Assuming the input is for the offer
            created_at: new Date().toISOString() // Auto-generating the created_at field
        };
    
        try {
            console.log(inputs);
      
            const response = await axios.post("https://www.hemkarbamid.az/hemkarbamid/offers.php/", inputs);
      
            toast.success("Form göndərildi");
      
            setInputValue(""); // Clear the input
      
            navigate('/');
      
        } catch (error) {
            console.error("Sipariş gönderilirken bir hata oluştu:", error);
            toast.error("Form göndərilərkən xəta baş verdi");
        }
    } else {
        navigate('/login')
    }
};



  return (
    <>
    <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
           <div class="auto-container">
               <h1>{t("RƏY VƏ TƏKLİF")}</h1>
           </div>
       </section>
       <section class="page-info">
           <div class="auto-container clearfix">
               <div class="pull-left"><h2>{t("Rəy və təklif")}</h2></div>
               <div class="pull-right">
                   <ul class="bread-crumb clearfix">
                       <li><a href="/">{t("Əsas səhifə")}</a></li>
                       <li>{t("Rəy və təklif")}</li>
                   </ul>
               </div>
           </div>
       </section>
       <section class="team-section">
    <div class="auto-container">
    
        {/* <!--Styled Heading--> */}
        <div class="styled-heading centered">
            <h2>{t("Rəy və ya təklifinizi yazın")}</h2>
        </div>
        
        <div class="row clearfix">
            
        <div class="text margin-bott-40">
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
        <div className="mb-4">
          <input
            type="text"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
            placeholder="Yazın..."
          />
        </div>
        <button
          type="submit"
          className="bg-[#223555] rounded-xl text-white py-4 px-8 hover:bg-gray-800"
        >
          {t("Göndər")}
        </button>
      </form>
        </div>
            
            
            
        </div>
        
    </div>
</section>
{userId ? <Polls/> : ''}
       </>
  )
}

export default Offers