import React from 'react'
import { useTranslation } from 'react-i18next';

const Activity = () => {

    const { t, i18n } = useTranslation();
  return (
    <>
    <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
            <div class="auto-container">
            	<h1>{t("Fəaliyyətimiz")}</h1>
            </div>
        </section>
        <section class="page-info">
            <div class="auto-container clearfix">
            	<div class="pull-left"><h2>{t("Fəaliyyətimiz")}</h2></div>
                <div class="pull-right">
                	<ul class="bread-crumb clearfix">
                    	<li><a href="/">{t("Əsas səhifə")}</a></li>
                        <li>{t("Fəaliyyətimiz")}</li>
                    </ul>
                </div>
            </div>
        </section></>
  )
}

export default Activity