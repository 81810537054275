import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import { ToastContainer } from "react-toastify";
import About from "./Pages/About/About";
import Faq from "./Pages/Faq/Faq";
import Team from "./Pages/Team/Team";
import Partners from "./Pages/Partners/Partners";
import Documents from "./Pages/Documents/Documents";
import Events from "./Pages/Events/Events";
import Activity from "./Pages/Activity/Activity";
import News from "./Pages/News/News";
import Register from "./Pages/Register/Register";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useEffect } from "react";
import EventDetails from "./Pages/Events/EventDetails";
import NewsDetails from "./Pages/News/NewsDetails";
import Login from "./Pages/Login/Login";
import Statistics from "./Pages/Statistics/Statistics";
import Offers from "./Pages/Offers/Offers";
import Laws from "./Pages/Laws/Laws";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/CreateUser/CreateUser";
import EditUser from "./Pages/EditUser/EditUser";
import Protected from "./Pages/Protected/Protected";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Pending from "./Pages/Pending/Pending";
import DashboardOffers from "./Pages/DashboardOffers/DashboardOffers";
import SearchResults from "./Pages/SearchResults/SearchResult";
import Polls from "./Pages/Polls/Polls";
import DashboardPolls from "./Pages/DashboardPolls/DashboardPolls";
import DashboardResults from "./Pages/DashboardResults/DashboardResults";



const Layout = () => {
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <AboveHeader/> */}
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route path="/" element={ <Home />}></Route>
        <Route path="/about" element={ <About />}></Route>
        <Route path="/faq" element={ <Faq />}></Route>
        <Route path="/team" element={ <Team />}></Route>
        <Route path="/partners" element={ <Partners />}></Route>
        <Route path="/documents" element={ <Documents />}></Route>
        <Route path="/events" element={ <Events />}></Route>
        <Route path="/events/:id" element={ <EventDetails />}></Route>
        <Route path="/activity" element={ <Activity />}></Route>
        <Route path="/news" element={ <News />}></Route>
        <Route path="/news/:id" element={ <NewsDetails />}></Route>
        <Route path="/register" element={ <Register />}></Route>
        <Route path="/login" element={ <Login />}></Route>
        <Route path="/statistics" element={ <Statistics />}></Route>
        <Route path="/offers" element={ <Offers />}></Route>
        <Route path="/polls" element={ <Polls />}></Route>
        <Route path="/laws" element={ <Laws />}></Route>
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/dashboard/pending" element={<Pending />} /> 
        <Route path="/dashboard/offers" element={<DashboardOffers />} />
        <Route path="/dashboard/polls" element={<DashboardPolls />} />
        <Route path="/dashboard/results" element={<DashboardResults />} />
        <Route path="/search/:query" element={<SearchResults />} />
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="dashboard/create"  element={<Protected Component={CreateUser} />} />
      <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} />
      </Route>
    </Route>
  )
);

function App() {

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = 'https://embed.tawk.to/664479d69a809f19fb3116e7/1httnnsa4';
  //   script.charset = 'UTF-8';
  //   script.setAttribute('crossorigin', '*');
  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  return (
    <div className="font-bodyFont bg-[#F7FAFD]">
       <FloatingWhatsApp  phoneNumber='+994502062566' accountName="SOCAR Hemkarbamid" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
      <RouterProvider router={router} />
    </div>
  ); 
}

export default App;




