import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function DashboardOffers() {
  const navigate = useNavigate(); // "naviget" yerine "navigate" kullanılması önerilir

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/offers.php/", {
        withCredentials: false,
        headers: {
          'Cache-Control': 'no-cache', // Önbelleği devre dışı bırakır
        },
      })
      .then(function (response) {
        const filteredProducts = response.data
        setProducts(filteredProducts);
      });
  }
  

  const deleteUser = (id) => {
    axios
      .delete(`https://www.hemkarbamid.az/hemkarbamid/offers.php/${id}/delete`, {
        withCredentials: false,
      })
      .then(function (response) {
        getProducts(); // Ürünleri tekrar alarak listeyi güncelle
      });
  };

  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <table>
            <thead>
              <tr>
                <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Təklif</th>
                <th>Tarix</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.offer}</td>
                  <td>{user.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

