import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {

    const { t, i18n } = useTranslation();
  return (
    <>
     <footer class="main-footer">
            
            {/* <!--Footer Upper-->         */}
            <div class="footer-upper" style={{backgroundImage:"url(assets/images/background/footer-bg.jpg)"}}>
                <div class="auto-container">
                    <div class="row clearfix">
                        
                        {/* <!--Two 4th column--> */}
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <div class="footer-widget about-widget">
                                        <h3>{t("Haqqımızda")}</h3>
                                        <div class="text">
                                            <p>{t("SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır.")}</p>
                                            <p>{t("Zavod il ərzində 650-660 min ton istehsal gücünə malikdir.")} </p>
                                        </div>
                                        
                                        <a href="/register" class="theme-btn btn-style-one">{t("Üzvlük")}</a>
                                        
                                    </div>
                                </div>
                                
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <h3>{t("Sürətli keçidlər")}</h3>
                                    <div class="row clearfix">
                                        
                                            <div style={{marginLeft:'17px'}} class="footer-widget links-widget">
                                                <ul>
                                                    <li><a href="/about">{t("Haqqımızda")}</a></li>
                                                    <li><a href="/partners">{t("Tərəfdaşlarımız")}</a></li>
                                                    <li><a href="/faq">{t("FAQ")}</a></li>
                                                    <li><a href="/events">{t("Tədbirlər")}</a></li>
                                                    <li><a href="/news">{t("Xəbərlər")}</a></li>
                                                    <li><a href="/statistics">{t("Statistika")}</a></li>
                                                    <li><a href="/laws">{t("Qanunvericilik")}</a></li>
                                                    <li><a href="/documents">{t("Sənəd nümunələri")}</a></li>
                                                </ul>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* < */}
                          {/* !--Two 4th column End--> */}
                        
                        {/* <!--Two 4th column--> */}
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="row clearfix">
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <div class="footer-widget twitter-feeds">
                                        <h3>{t("Ən son tədbirlər")}</h3>	
                                        <div class="feed">
                                            <div class="icon"><span class="fa-solid fa-newspaper"></span></div>
                                            <div class="feed-content"><a href="/events/1">“Əsrin müqaviləsi”nin 30 illiyi münasibətilə tədbir keçirilib</a></div>
                                            <div class="time">10 dəqiqə əvvəl</div>
                                        </div>
                                        
                                        <div class="feed">
                                        <div class="icon"><span class="fa-solid fa-newspaper"></span></div>
                                        <div class="feed-content"><a href="/events/1">“Əsrin müqaviləsi”nin 30 illiyi münasibətilə tədbir keçirilib</a></div>
                                            <div class="time">20 dəqiqə əvvəl</div>
                                        </div>
                                        
                                        <div class="feed">
                                            <div class="icon"><span class="fa-solid fa-newspaper"></span></div>
                                            <div class="feed-content"><a href="/events/1">“Əsrin müqaviləsi”nin 30 illiyi münasibətilə tədbir keçirilib</a></div>
                                            <div class="time">1 saat əvvəl</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                <div class="footer-widget twitter-feeds">
                                        <h3>{t("Əlaqə")}</h3>	
                                        <div class="feed">
                                            <div class="icon"><span class="fa-solid fa-location-dot"></span></div>
                                            <div class="feed-content"><a href="#">{t("Ünvanımız")}: </a>  {t("Sumqayıt şəhəri")}, {t("Quba yolu, 35-ci km")}</div>
                                        </div>
                                        <div class="feed">
                                            
                                            <div class="icon"><span class="fa-solid fa-address-book"></span></div>
                                            <div class="feed-content"><a href="#">{t("Bizimlə Əlaqə")}</a></div>
                                            <div class="time">+994 50 323 22 22</div>
                                            <div class="time">+994 55 282 48 23</div>
                                            <div class="time">
                                            hemkarbamid@info.az</div>
                                        </div>
                                        
                                        <div class="feed">
                                            <div class="icon"><span class="  fa-solid fa-clock"></span></div>
                                            <div class="feed-content"><a href="#">{t("İş saatları")}</a></div>
                                            <div class="time">{t("Bazar ertəsi-Cümə")}: 08.00 - 17.00</div>
                                            <div class="time">{t("Şənbə-Bazar istirahət")}</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 
                        </div>
                        {/* <!--Two 4th column End--> */}
                        
                    </div>
                    
                </div>
            </div>
            
            {/* <!--Footer Bottom--> */}
            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-md-6 col-sm-6 col-xs-12"><div class="copyright-text">Copyrights © 2024 {t("Bütün hüquqlar qorunur")}</div></div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            {/* <!--Bottom Nav--> */}
                            {/* <nav class="footer-nav clearfix">
                                <ul class="pull-right clearfix">
                                    <li><a href="#">Terms Of Use </a></li>
                                    <li><a href="#">Privacy &amp; Security Statement</a></li>
                                </ul>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </footer>
    </>
  );
}
