import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/responsive.css'
import './assets/css/bootstrap.css'
import './assets/css/font-awesome.css'
import './assets/css/style.css'
import './assets/css/animate.css'
import './assets/css/flaticon.css'
import './assets/css/hover.css'
import './assets/css/jquery.fancybox.css'
import './assets/css/jquery.mCustomScrollbar.min.css'
import './assets/css/owl.css'
import './assets/css/revolution-slider.css'
import { store, persistor } from "./Components/redux/store";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}> 
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
