import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from '../../Components/redux/authSlice';
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [finKod, setFinKod] = useState("");
  const [members, setMembers] = useState([]); // Tüm üyeleri burada saklayacağız
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Component mount olduğunda kullanıcıları GET ile al
  useEffect(() => {
    getMembers();
  }, []);

  function getMembers() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        // Filtreleme işlemi burada yapılıyor
        const filteredMembers = response.data.filter(member => member.confirm == 1);
        setMembers(filteredMembers);
      }); 
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Formun varsayılan gönderimini engelleyin

    // Girilen hidNumber ve password'ü kontrol et
    const user = members?.find(
      (member) => member.finKod === finKod && member.password === password
    );

    if (user) {
      // Giriş başarılıysa
      toast.success("Uğurlu giriş!");
      dispatch(login({ firstName: user.name, lastName: user.surname, department: user.department_id, userId: user.id }));
      navigate("/"); // Anasayfaya yönlendirin
    } else {
      // Giriş bilgileri hatalıysa
      toast.error("FIN kod və ya şifrə səhvdir.");
    }
  };

  return (
    <>
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/pagetitle-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>{t("GİRİŞ")}</h1>
        </div>
      </section>
      <section className="page-info">
        <div className="auto-container clearfix">
          <div className="pull-left">
            <h2>{t("Giriş")}</h2>
          </div>
          <div className="pull-right">
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">{t("Əsas səhifə")}</a>
              </li>
              <li>{t("Giriş")}</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="team-section">
        <div className="auto-container">
          <div className="styled-heading centered">
            <h2>{t("Giriş")}</h2>
          </div>
          <div className="row clearfix">
            <div className="p-6 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700">
                   {t("Şəxsiyyət vəsiqəsi FİN Kod")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={finKod}
                    onChange={(e) => setFinKod(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="FİN Kod"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    {t("Şifrə")} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-[#223555] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    {t("Giriş")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
