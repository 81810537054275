import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './Carousel.css'
import { useTranslation } from "react-i18next";

const Carousel = () => {

  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: 'assets/images/news.jpg', title:'Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir.', text: "SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir." },
    { img: 'assets/images/news.jpg', title:'Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək', text: "SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır." }
  ];
 
  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500); // Show text after 0.5 seconds

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500); // Show button after 1.5 seconds

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000, // Delay before auto-slide
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="slide-content relative flex justify-between">
          <img src={slide.img} alt={`Slide ${index + 1}`} className="w-[400px] h-[400px] object-cover" />
          <div className="flex items-center justify-center bg-[#F8F8F4] flex-1">
      <div className="w-[80%] mx-auto text-center  flex-col justify-between items-end">
        <h1 className="text-4xl font-bold mb-4">{t(slide.title)}</h1>
        <p className="text-lg mb-6">
         {t(slide.text)}
        </p>
      <a href="/news">  <button className="bg-[#223555] rounded-xl text-white py-4 px-8 hover:bg-gray-800 ">
          {t("BÜTÜN XƏBƏRLƏR")}
        </button></a>
      </div>
    </div>
          </div>
        </SwiperSlide> 
      ))}
    </Swiper>
  );
};

export default Carousel;





















































































  
// <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/2.jpg" data-saveperformance="off" data-title="Awesome Title Here" class="tp-revslider-slidesli active-revslide" style="width: 100%; height: 100%; overflow: hidden; z-index: 18; visibility: hidden; opacity: 0;">
// <div class="slotholder" style="width:100%;height:100%;" data-duration="undefined" data-zoomstart="undefined" data-zoomend="undefined" data-rotationstart="undefined" data-rotationend="undefined" data-ease="undefined" data-bgpositionend="undefined" data-bgposition="left top" data-kenburns="undefined" data-easeme="undefined" data-bgfit="cover" data-bgfitend="undefined" data-owidth="undefined" data-oheight="undefined"><div class="tp-bgimg defaultimg" data-lazyload="undefined" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" data-lazydone="undefined" src="images/main-slider/2.jpg" data-src="images/main-slider/2.jpg" style="background-color: rgba(0, 0, 0, 0); background-repeat: no-repeat; background-image: url(&quot;images/main-slider/2.jpg&quot;); background-size: cover; background-position: left top; width: 100%; height: 100%; opacity: 1; visibility: inherit;"></div></div> 

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="left" data-hoffset="-270" data-y="bottom" data-voffset="30" data-speed="1500" data-start="500" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; left: -184px; top: 67px; visibility: visible; opacity: 0; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, -50, 0, 0, 1);"><div class="logo-image"><img src="images/main-slider/man-image.png" alt="" style="width: 591px; height: 683px;"></img></div></div>

// <div class="tp-caption sfr sfb tp-resizeme start" data-x="center" data-hoffset="0" data-y="center" data-voffset="-140" data-speed="1500" data-start="1000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; left: 631.5px; top: 165.5px; visibility: visible; opacity: 0; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, 50, 0, 0, 1);"><div class="logo-image"><img src="images/resource/central-logo.png" alt="" style="width: 109px; height: 109px;"></img></div></div>

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="center" data-hoffset="0" data-y="center" data-voffset="0" data-speed="1500" data-start="1000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 363px; top: 288px; visibility: visible; opacity: 0; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, -50, 0, 0, 1);"><h1 class="text-center" style="transition: all; min-height: 0px; min-width: 0px; line-height: 72px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 60px;">Senior Defence Lawyer <br style="transition: all; min-height: 0px; min-width: 0px; line-height: 72px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 60px;"></br>L.K. Antony</h1></div>

// <div class="tp-caption sfr sfb tp-resizeme start" data-x="center" data-hoffset="0" data-y="center" data-voffset="110" data-speed="1500" data-start="1000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 580px; top: 455px; visibility: visible; opacity: 0; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, 50, 0, 0, 1);"><div class="phone-no" style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 24px;">Call: +321 589 2369</div></div>

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="center" data-hoffset="0" data-y="center" data-voffset="180" data-speed="1500" data-start="1000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 546px; top: 517px; visibility: visible; opacity: 0; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, -50, 0, 0, 1);"><div class="text-center" style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px;"><a href="#" class="theme-btn btn-style-one" style="transition: 0.2s ease-out; min-height: 0px; min-width: 0px; line-height: 24px; border-width: 0px; margin: 0px; padding: 11px 60px; letter-spacing: 0px; font-size: 14px;">Make an Appoinment</a></div></div>


// </li>


// <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/3.jpg" data-saveperformance="off" data-title="Awesome Title Here" class="tp-revslider-slidesli active-revslide current-sr-slide-visible" style="width: 100%; height: 100%; overflow: hidden; visibility: inherit; opacity: 1; z-index: 20;">
// <div class="slotholder" style="width:100%;height:100%;" data-duration="undefined" data-zoomstart="undefined" data-zoomend="undefined" data-rotationstart="undefined" data-rotationend="undefined" data-ease="undefined" data-bgpositionend="undefined" data-bgposition="right top" data-kenburns="undefined" data-easeme="undefined" data-bgfit="cover" data-bgfitend="undefined" data-owidth="undefined" data-oheight="undefined"><div class="tp-bgimg defaultimg" data-lazyload="undefined" data-bgfit="cover" data-bgposition="right top" data-bgrepeat="no-repeat" data-lazydone="undefined" src="images/main-slider/3.jpg" data-src="images/main-slider/3.jpg" style="background-color: rgba(0, 0, 0, 0); background-repeat: no-repeat; background-image: url(&quot;images/main-slider/3.jpg&quot;); background-size: cover; background-position: right top; width: 100%; height: 100%; opacity: 1; visibility: inherit;"></div></div> 


// <div class="tp-caption sfl sfb tp-resizeme start" data-x="left" data-hoffset="15" data-y="center" data-voffset="-150" data-speed="1500" data-start="500" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 101px; top: 195px; visibility: visible; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.0025, 0, 0, 0, 1);"><div class="smaller-title" style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 24px;">We Are Lawyer Justice</div></div>

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="left" data-hoffset="15" data-y="center" data-voffset="-50" data-speed="1500" data-start="1000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 101px; top: 238px; visibility: visible; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.0025, 0, 0, 0, 1);"><h1 class="text-left" style="transition: all; min-height: 0px; min-width: 0px; line-height: 72px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 60px;">Attrorneys Fighting For <br style="transition: all; min-height: 0px; min-width: 0px; line-height: 72px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 60px;"></br>Your Freedom</h1></div>

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="left" data-hoffset="15" data-y="center" data-voffset="60" data-speed="1500" data-start="1500" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 101px; top: 390px; visibility: visible; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.0025, 0, 0, 0, 1);"><div class="text" style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 16px;">We Have Helped Thousands of People to Get Relief From National Wide Fights <br style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 16px;"></br>WrongfullDenials. Now They Trusted Our Attorneys.</div></div>

// <div class="tp-caption sfl sfb tp-resizeme start" data-x="left" data-hoffset="15" data-y="center" data-voffset="150" data-speed="1500" data-start="2000" data-easing="easeOutExpo" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.3" data-endspeed="1200" data-endeasing="Power4.easeIn" style="z-index: 4; white-space: nowrap; transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px; left: 101px; top: 487px; visibility: visible; opacity: 0.995775; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00249, -0.21127, 0, 0, 1);"><div class="text-center" style="transition: all; min-height: 0px; min-width: 0px; line-height: 30px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-size: 15px;"><a href="#" class="theme-btn btn-style-one" style="transition: 0.2s ease-out; min-height: 0px; min-width: 0px; line-height: 24px; border-width: 0px; margin: 0px; padding: 11px 60px; letter-spacing: 0px; font-size: 14px;">Contact Our Attorneys</a></div></div>

// </li>