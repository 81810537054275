import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel';
import Titles from '../../Components/home/Titles/Titles';
import News from '../../Components/home/Events/Events';
import Workers from '../../Components/home/Workers/Workers';
import Newsletter from '../../Components/Newsletter/Newsletter';
import Events from '../../Components/home/Events/Events';
import Martys from '../../Components/home/Martys/Martys';
import Partners from '../../Components/home/Partners/Partners';




const Home = () => {
  return (
    <>
   <Carousel/>
  {/* <Titles/>
  <Events/>
  <Martys/> */}
  <Workers/>
  {/* <Newsletter/> */}
  <Partners/>
    </>
  )
}

export default Home;
