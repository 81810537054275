import React, { useEffect, useState } from 'react';
import { FaSearch, FaUser, FaQuestionCircle, FaShoppingCart, FaTh, FaChevronRight } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../redux/authSlice';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import { useNavigate } from 'react-router-dom';

const languageOptions = [
  { value: "en", label: "EN", },
  { value: "az", label: "AZ",  },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});
  


const Header = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const memberName = useSelector((state) => state.auth.member);
  const dispatch = useDispatch();
  console.log(isLoggedIn)

  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Arama sorgusunu yeni bir route'a yönlendir
    navigate(`/search/${searchQuery}`);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
        const language = languageOptions.find(lang => lang.value === savedLanguage);
        if (language) {
            setSelectedLanguage(language);
            i18n.changeLanguage(language.value);
        }
    }
}, []);


const handleLanguageChange = (language) => {
  setSelectedLanguage(language);
  setIsOpen(false);
  i18n.changeLanguage(language.value);
  localStorage.setItem("language", language.value);
};

  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Hesabınızdan çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        toast.error("Hesabınızdan çıxıldı")
      }
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    console.log(`Selected language: ${language}`);
    setIsOpen(false); // Dropdown'u kapat
  };


  return ( 
    <>
      <header class="main-header">
            {/* <!-- Top Bar --> */}
            <div class="top-bar">
                <div class="auto-container clearfix">
                    {/* <!-- Top Left --> */}
                    {/* <div class="top-left"> */}
                    {/* <div class="social-links">
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}} class="fa fa-facebook-f"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-twitter"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-google-plus"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-linkedin"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-skype"></span></a>
                                        </div> */}
                    {/* </div> */}
                    
                    {/* <!-- Top Right --> */}
                    
                </div>
            </div>
            {/* <!-- Top Bar End --> */}
            
            {/* <!--Middle Bar--> */}
            <div class="middle-bar">
            	<div class="auto-container">
                	<div class="clearfix">
                    <div class="logo-column">
                    <div className="flex space-x-2 p-2 w-1/6 mx-auto rounded-md">
 
    <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 px-4 py-4 border "
            >
              <span>{selectedLanguage.label}</span>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-6 w-28 shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-slate-400 hover:text-[white]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="relative inline-block">
      <button
        className="flex items-center justify-center w-16 h-16 bg-white rounded-md border-gray-100 border-2"
        onClick={handleSearchClick}
      >
        <span role="img" aria-label="search">
          <span className="fa fa-search"></span>
        </span>
      </button>

      {isSearching && (
        <form onSubmit={handleSearchSubmit} className="absolute mt-2">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search..."
            className="border border-gray-300 rounded-md px-4 py-2 w-64"
          />
        </form>
      )}
    </div>
    </div>
                        </div>
                        {/* <!--Info Column--> */}
                        <div class="info-column pull-left">
                        <figure class="logo"><a href="/"><img src="/assets/images/logo.jpg" alt="" title="SOCAR HIK"></img></a></figure>
                        </div>
                        
                        {/* <!--Info Column--> */}
                        <div class="info-column pull-right">
                       <a href='/'> <img src="/assets/images/socar2.jpg" className='h-[100px] w-[200px]' alt="" title="SOCAR HIK"></img></a>
                        
                            {/* <p>Ünvan: Bakı, Azərbaycan</p>
                            <p>Əlaqə: +994 50 383 22 22</p> */} 
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <!-- Lower Section --> */}
            <div class="lower-section">
                <div class="auto-container">
                    
                    {/* <!--Outer Box--> */}
                    <div class="outer-box clearfix">
                         
                        {/* <!-- Hidden Nav Toggler --> */}
                        <div class="nav-toggler">
                        <button class="hidden-bar-opener"><span class="icon fa fa-bars"></span></button>
                        </div>
                        {/* <!-- / Hidden Nav Toggler --> */}
                         
                        
                        {/* <!-- Main Menu --> */}
                        <nav class="main-menu">
                            
                            <div class="navbar-header">
                                {/* <!-- Toggle Button -->    	 */}
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                            </div>
                            
                            <div class="navbar-collapse collapse clearfix">
                                <ul class="navigation">
                                    <li class="current"><a href="/">{t("Əsas səhifə")}</a></li>
                                    <li class="dropdown"><a href="#">{t("Haqqımızda")}</a>
                                    	<ul>
                                            <li><a href="/about">{t("Məlumat")}</a></li>
                                            <li class="dropdown"><a href="/team">{t("Komandamız")}</a>
                                            </li>
                                            <li><a href="/partners">{t("Tərəfdaşlarımız")}</a></li>
                                            <li><a href="/faq">{t("FAQ")}</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">{t("Xidmətlər")}</a>
                                    	<ul>
                                            <li><a href="/events">{t("Tədbirlər")}</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">{t("İşlərimiz")}</a>
                                    	<ul>
                                            <li><a href="/news">{t("Xəbərlər")}</a></li>
                                            <li><a href="/statistics">{t("Statistika")}</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">{t("Sənədlər")}</a>
                                    	<ul>
                                            <li><a href="/laws">{t("Qanunvericilik")}</a></li>
                                            <li><a href="/documents">{t("Sənəd nümunələri")}</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="/offers">{t("Rəy və Təklif")}</a></li>
                                </ul>
                            </div>
                        </nav>
                        {/* <!-- Main Menu End--> */}
                        
                        <nav>
                        <ul>
      <li>
        {isLoggedIn ? (
          <div onClick={handleLogout} className="link-box">
            <p className='theme-btn cursor-pointer'>{memberName}</p>
          </div>
        ) : (
          <>
          <div className="link-box">
            <a href="/register" className="theme-btn">{t("ÜZVLÜK")}</a>
          </div>
        </>
        )}
      </li>
    </ul>

    </nav>
                        
                    </div>
                </div>
            </div>
            {/* <!-- Lower Section End--> */}
            
        </header>
    </>
  );
};

export default Header;




























